<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
        <div>
            <tabsComp :tabs="tabs" />
            
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
                <v-row class="mt-10">
                    <v-col
                    cols="12"
                    md="10"
                    sm="12">

                    </v-col>
                </v-row>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ExportsPrint from '@/components/exports-print.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'

export default {
    components: {
        ExportsPrint, Footer, tabsComp, HeaderPortrate
    },
    data() {
        return {

        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      tabs: function()
      {
        const menu = this.$store.getters['state'].menu;
        const license = this.$store.getters['state'].licenseType.activemenu;
        const alicense = this.$store.getters['state'].licenseType
        let currentMenu = menu[license];
        
        if(!license){
            return false;
        }
        let cMenu = {main: {},sub:[]};
        Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            // console.log("cM",cM);
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
        });
        
        let t = []
        // console.log("cMenu",cMenu);
        Object.values(cMenu).forEach(value =>{
        
            if(this.$route.path == value.href){
                value.class = "mytab";
            }else{
                value.class = "mytab4";
            }
            if(this.lang.langname == 'ar'){
                value.name = value.arname
            }else{
                value.name = value.enname
            }
            
            for(let i =0; i< value.groupid.length;i++){
                if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                }
            }
        })
    
        return t;
      }
    }
}
</script>
